@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
    font-family: 'Montserrat', sans-serif;
}

a {
    text-decoration: none;
    color: #0E5AA7;
    font-weight: 600;
}

.Toastify__toast-container--top-center {
    top: 60px;
    min-width: 500px;
    max-width: 650px;
    border-radius: 50%;
}

.Toastify__toast-container { 
    width: auto;
}

.Toastify__toast {
    font-weight: 500;
    border-radius: 8px;
    font-family: 'Montserrat', sans-serif;
}

.Toastify__toast--success {
    background-color: #009688;
}

.Toastify__toast--error {
    background-color: #F34041;
}

.Toastify__toast svg.close-icon {
    height: 16px;
    fill: white;
    margin: auto;
    margin-right: 8px;
}
#mapContainer {
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.gm-ui-hover-effect {
  display: none !important;
}

.gm-style-iw .gm-style-iw-c {
  padding: 0;
  margin: 0;
}

.gm-style-iw-d {
  padding: 0;
  margin: 0;
}

/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:100,200,300,400,500,600,700,800'); */

* {
    margin: 0;
    padding: 0;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Montserrat', sans-serif !important;
}
