@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
}

a {
    text-decoration: none;
    color: #0E5AA7;
    font-weight: 600;
}

.Toastify__toast-container--top-center {
    top: 60px;
    min-width: 500px;
    max-width: 650px;
    border-radius: 50%;
}

.Toastify__toast-container { 
    width: auto;
}

.Toastify__toast {
    font-weight: 500;
    border-radius: 8px;
    font-family: 'Montserrat', sans-serif;
}

.Toastify__toast--success {
    background-color: #009688;
}

.Toastify__toast--error {
    background-color: #F34041;
}

.Toastify__toast svg.close-icon {
    height: 16px;
    fill: white;
    margin: auto;
    margin-right: 8px;
}