#mapContainer {
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.gm-ui-hover-effect {
  display: none !important;
}

.gm-style-iw .gm-style-iw-c {
  padding: 0;
  margin: 0;
}

.gm-style-iw-d {
  padding: 0;
  margin: 0;
}
